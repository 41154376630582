import { Theme } from '@mui/material';
import Accordion from './Accordion';
import Baseline from './Baseline';
import Breadcrumbs from './Breadcrumbs';
import Button from './Button';
import Card from './Card';
import Checkbox from './Checkbox';
import Chip from './Chip';
import DatePicker from './DatePicker';
import Dialog from './Dialog';
import Divider from './Divider';
import Icons from './Icons';
import Input from './Input';
import Menu from './Menu';
import Popover from './Popover';
import Select from './Select';
import Stepper from './Stepper';
import Tabs from './Tabs';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function overrides(theme: Theme) {
  return Object.assign(
    Accordion(theme),
    Baseline(),
    Breadcrumbs(theme),
    Button(theme),
    Card(theme),
    Checkbox(),
    Chip(theme),
    DatePicker(theme),
    Dialog(theme),
    Divider(),
    Icons(),
    Input(theme),
    Menu(theme),
    Popover(theme),
    Select(theme),
    Stepper(theme),
    Tabs(theme),
  );
}
