import { Components, Theme } from '@mui/material';

export default function Popover(theme: Theme): Components {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          padding: theme.spacing(1.5),
          color: theme.palette.text.primary,
          border: `1px solid ${theme.palette.grey[200]}`,
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05)',
          background: theme.palette.common.white,
          borderRadius: Number(theme.shape.borderRadius) * 2,
          marginTop: theme.spacing(0.5),
        },
      },
    },
  };
}
