import { FC } from 'react';
import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { NavigationMenu } from 'src/routes/layouts/default-layout/components/NavigationMenu';
import LoginButton from './LoginButton';
import SignupButton from './SignupButton';

const Layout = {
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
};

const NavLayout = {
  position: 'absolute',
  left: '0',
  height: '100%',
};

const Registration: FC = () => {
  return (
    <>
      <Box sx={Layout as SxProps<Theme>}>
        <Box sx={NavLayout as SxProps<Theme>}>
          <NavigationMenu />
        </Box>
        <LoginButton />
        <SignupButton />
      </Box>
    </>
  );
};

export default Registration;