import { Components } from '@mui/material';

export default function Divider(): Components {
  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#EEEEEE',
        },
      },
    },
  };
}
