import React, { useContext, createContext, useState } from 'react';

interface EventParam {
  srcIp: string | null;
  dstIp: string | null;
  srcPort: number | null;
  dstPort: number | null;
}

interface EventContextData {
  eventParam: EventParam;
  updateEventParam: (newValue: EventParam) => void;
}

//create the context
const EventContext = createContext<EventContextData>({
  eventParam: {
    srcIp: null,
    dstIp: null,
    srcPort: null,
    dstPort: null,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateEventParam: () => {}
});

export function EventContextProvider({ children }: { children: React.ReactNode }):JSX.Element {
  const storedEventParam= localStorage.getItem('eventParam');
  const initEventParam= storedEventParam ? JSON.parse(storedEventParam) : {
      srcIp: null,
      dstIp: null,
      srcPort: null,
      dstPort: null,
  }

  const [eventParam, setEventParam] = useState<EventParam>(initEventParam);

  const updateEventParam = (newValue: EventParam):void => {
    setEventParam(newValue);
    localStorage.setItem('eventParam',JSON.stringify(newValue));
  };

  const contextValue: EventContextData = {
    eventParam,
    updateEventParam,
  };

  return <EventContext.Provider value={contextValue}>{children}</EventContext.Provider>;
}

// Custom hook to access the context
export const useEventContext= ():EventContextData => {
  return useContext(EventContext)
}
