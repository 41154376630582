import { CircularProgress } from '@mui/material';
import React from 'react';

const Loading = (): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#1A2326',
        height: '100vh',
      }}
    >
      <CircularProgress color='inherit' />
    </div>
  );
};

export default Loading;
