import React, { ReactElement, ReactNode } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, from, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';

export function CustomApolloProvider({ children }: { children: ReactNode }): ReactElement {
  const { getAccessTokenSilently, logout } = useAuth0();

  const client = React.useMemo(() => {
    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessTokenSilently();

      if (!token) {
        logout({
          returnTo: window.location.origin,
        });

        return headers;
      }

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    const httpLink = new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URL || 'http://localhost:4000/graphql',
    });

    return new ApolloClient({
      link: from([authLink, httpLink]),
      cache: new InMemoryCache({
        typePolicies: {
          NetflowQueryType: {
            merge: true,
          },
        },
      }),
    });
  }, []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
