import { Components } from '@mui/material';

export default function Baseline(): Components {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        'body .MuiDrawer-paperAnchorLeft': {
          border: 'none',
        },
      },
    },
  };
}
