import { useAuth0 } from '@auth0/auth0-react';
import { ERoutes } from 'src/types/routes.enum';
import { Button } from '@mui/material';
import getUrlParamsObj from 'src/core/utils/urlParams';
import { FC } from 'react';

const LoginButton: FC = () => {
  const { loginWithRedirect } = useAuth0();
  const handleLogin = async (): Promise<void> => {
    const urlParams = getUrlParamsObj();
    const invitation = urlParams.get('invitation');
    const organization = urlParams.get('organization');
    await loginWithRedirect({
      appState: {
        returnTo: ERoutes.Profile,
      },
      ...(invitation && { invitation: invitation }),
      ...(organization && { organization: organization }),
    });
  };

  return (
    <Button variant='contained' onClick={handleLogin} sx={{ margin: '20px' }}>
      Log In
    </Button>
  );
};
export default LoginButton;