import { Suspense } from 'react';
import { Route, Routes } from 'react-router';
import {
  EventsPageLazy,
  HomePageLazy,
  IncidentsPageLazy,
  ProfilePageLazy,
  SingleEventPageLazy,
  SingleIncidentPageLazy,
  StoryboardPageLazy,
  SingleStoryboardPageLazy,
} from 'src/routes/lazy-routes';
import { DefaultLayout } from 'src/routes/layouts/default-layout/DefaultLayout';
import { ERoutes } from 'src/types/routes.enum';

export const AppNavigation = (): JSX.Element => {
  

  return (
    <>
      <Suspense>
        <Routes>
          <Route path='/' element={<DefaultLayout />}>
            <Route index element={<HomePageLazy />} />
            <Route path={ERoutes.Profile} element={<ProfilePageLazy />} />
            <Route path={ERoutes.Incidents} element={<IncidentsPageLazy />} />
            <Route path={`${ERoutes.Incidents}/:id`} element={<SingleIncidentPageLazy />} />
            <Route path={ERoutes.Events} element={<EventsPageLazy />} />
            <Route path={`${ERoutes.Events}/:id`} element={<SingleEventPageLazy />} />
            <Route path={ERoutes.Storyboard} element={<StoryboardPageLazy />} />
            <Route path={`${ERoutes.Storyboard}/:id`} element={<SingleStoryboardPageLazy />} />
            <Route path={ERoutes.Algorythm} element={<>Algorythm</>} />
          </Route>

          <Route path='*' element={<div>404</div>} />

          {/* TODO: Add redirects for * routes */}
        </Routes>
      </Suspense>
    </>
  );
};
