import {
  AlgorythmIcon,
  ProfileIcon,
  EventsIcon,
  HomeIcon,
  IncidentsIcon,
  StoryboardIcon,
} from 'src/theme/customIcons';
import { ERoutes } from 'src/types/routes.enum';

export interface IMainNavigationItem {
  name: string;
  route: ERoutes;
  icon: JSX.Element;
}

export const useGetMainNavConfig = (): IMainNavigationItem[] => [
  {
    name: 'Home',
    route: ERoutes.Home,
    icon: <HomeIcon />,
  },
  {
    name: 'Events',
    route: ERoutes.Events,
    icon: <EventsIcon />,
  },
  {
    name: 'Incidents',
    route: ERoutes.Incidents,
    icon: <IncidentsIcon />,
  },
  {
    name: 'Storyboard',
    route: ERoutes.Storyboard,
    icon: <StoryboardIcon />,
  },
  // {
  //   name: 'Algorythm',
  //   route: ERoutes.Algorythm,
  //   icon: <AlgorythmIcon />,
  // },
  {
    name: 'profile',
    route: ERoutes.Profile,
    icon: <ProfileIcon />,
  },
];
