import { Components, Theme } from '@mui/material';

export default function Input(theme: Theme): Components {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: Number(theme.shape.borderRadius) * 2,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.04)',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
          },
          '&.MuiInputBase-adornedStart': {
            paddingLeft: theme.spacing(1),
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main,
            },
          },
          '&.MuiSelect-root': {
            background: theme.palette.grey.A100,
            borderRadius: Number(theme.shape.borderRadius) * 2,
            '& .MuiOutlinedInput-notchedOutline ': {
              borderColor: theme.palette.common.white,
            },
            '&:hover': {
              '& .MuiSelect-select': {
                color: theme.palette.common.black,
              },
            },
            '&.Mui-focused': {
              '& .MuiSvgIcon-root:first-of-type': {
                color: theme.palette.primary.main,
              },
              '& .MuiInputBase-adornedStart': {
                borderColor: theme.palette.primary.main,
              },
            },
          },
        },
        input: {
          height: 'auto',
          padding: theme.spacing(1, 1.5),
          fontSize: 12,
          lineHeight: 12 / 12,
          color: '#1A2326',
          '&.MuiInputBase-inputAdornedStart': {
            padding: theme.spacing(1, 1, 1, 0),
          },
        },
        notchedOutline: {
          borderColor: theme.palette.grey[200],
        },
      },
    },
  };
}
