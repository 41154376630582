import { Components, Theme } from '@mui/material';

export default function Stepper(theme: Theme): Components {
  return {
    MuiStepLabel: {
      styleOverrides: {
        root: {
          flexDirection: 'column',
          gap: theme.spacing(1),
        },
        iconContainer: {
          padding: 0,
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          alignItems: 'flex-start',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          marginTop: 8,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: 20,
          height: 20,
          clipPath: 'polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0 25%)',
          '& circle': {
            r: 16,
          },
        },
        text: {
          fill: 'white',
        },
      },
    },
  };
}
