import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect } from 'react';
import { setupInterceptor } from 'src/api/fetcher';

export const useAxiosInterceptor = (): void => {
  const { getAccessTokenSilently, isLoading, isAuthenticated, error } = useAuth0();
  const onError = useCallback((errorMessage: string): void => {
    // TODO: Add toast notification with errorMessage
    console.log(errorMessage);
  }, []);

  useEffect(() => {
    setupInterceptor(onError, getAccessTokenSilently);
  }, [getAccessTokenSilently, onError, isAuthenticated]);

  useEffect(() => {
    // if (error && !isLoading) {
    // TODO: Add toast notification with errorMessage
    // console.log(errorMessage)
    // }
  }, [error, isLoading]);
};
