import { AppNavigation } from 'src/routes/app-navigation';
import { AuthenticatedProvider } from 'src/core/providers/authenticated-user.provider';


export const App = (): JSX.Element => {
  return (
    <AuthenticatedProvider>
      <AppNavigation />
    </AuthenticatedProvider>
  );
};
