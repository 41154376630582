import { PropsWithChildren } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAxiosInterceptor } from 'src/core/hooks/use-axios-interceptor';
import { Button } from '@mui/material';
import Registration from '../components/registration/Registration';
import Loading from '../components/Loading';

export const AuthenticatedProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const { isLoading, error, isAuthenticated, logout } = useAuth0();

  useAxiosInterceptor();

  if (isLoading) {
    return <Loading/>//<div>Loading...</div>;
  }

  if (error) {
    return (
      <>
        <div>Oops... {error.message}</div>
        <Button variant='contained' onClick={() => logout({returnTo: window.location.origin,})}>
          Logout
        </Button>
      </>
    );
  }

  if (!isAuthenticated) {
    //loginWithRedirect();

    return <Registration/>;
  }

  return <>{children}</>;
};
