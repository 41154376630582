import { Components, Theme } from '@mui/material';

export default function Accordion(theme: Theme): Components {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
          background: theme.palette.grey.A100,
          border: `1px solid ${theme.palette.common.white}`,
          boxShadow: '0px 7px 17px rgba(0, 0, 0, 0.04)',
          borderRadius: 4,
          transition: '0.3s ease',
          '&::before': {
            display: 'none',
          },
          '&:hover': {
            background: theme.palette.common.white,
          },
          '&.Mui-expanded': {
            background: theme.palette.common.white,
            borderColor: theme.palette.grey[200],
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          transition: '0.3s ease',
          margin: theme.spacing(0, -1),
          gap: theme.spacing(1.5),
          minHeight: 32,
          '&.Mui-expanded': {
            minHeight: 32,
          },
        },
        content: {
          margin: 0,
          '&.Mui-expanded': {
            marginBottom: 8,
            marginTop: 8,
          },
        },
        expandIconWrapper: {
          '& .MuiSvgIcon-root': {
            fontSize: 12,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0),
          margin: theme.spacing(0),
        },
      },
    },
  };
}
