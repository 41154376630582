import { alpha, Components, Theme } from '@mui/material';

export default function Menu(theme: Theme): Components {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          boxShadow: theme.shadows[10],
          backgroundColor: theme.palette.background.paper,
          backdropFilter: 'blur(4px)',
          marginTop: '0 !important',
          padding: 0,
        },
        popper: {
          '&[data-popper-placement*="top"]': {
            '& .MuiTooltip-tooltipArrow': {
              borderBottomLeftRadius: 0,
              marginBottom: `${theme.spacing(1)} !important`,
              marginLeft: theme.spacing(1),
            },

            '& .MuiTooltip-arrow': {
              marginBottom: -8,
              height: 8,
              width: 6,
              transform: 'none !important',
              left: '-1px !important',

              '&:before': {
                border: `1px solid ${theme.palette.grey[800]}`,
                background: '#1A2326',
              },
              '&:after': {
                content: "''",
                position: 'absolute',
                left: 0,
                top: 0,
                width: 1,
                background: theme.palette.grey[800],
                height: 5,
              },
            },
          },
        },
        tooltipArrow: {
          background: '#1A2326',
          border: `1px solid ${theme.palette.grey[800]}`,
          boxShadow: '0px 2px 30px rgba(0, 0, 0, 0.25)',
          padding: theme.spacing(0.5, 0.75),
          color: theme.palette.grey[100],
          fontSize: 10,
          fontWeight: 500,
          borderRadius: theme.shape.borderRadius,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: theme.spacing(1.5),
          border: `1px solid ${theme.palette.grey[200]}`,
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05)',
          background: theme.palette.common.white,
          borderRadius: Number(theme.shape.borderRadius) * 2,
        },
        list: {
          padding: 0,
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
        autoFocus: true,
      },
      styleOverrides: {
        root: {
          fontSize: theme.typography.body2.fontSize,
          lineHeight: theme.typography.body2.lineHeight,
          color: theme.palette.grey[800],
          padding: theme.spacing(1, 2),
          '&:hover, &:focus-within': {
            backgroundColor: alpha(theme.palette.primary.main, 0.04),
          },
        },
        focusVisible: {
          backgroundColor: alpha(theme.palette.primary.main, 0.04),
        },
        selected: {
          color: theme.palette.primary.main,
          backgroundColor: 'transparent',
        },
      },
    },
  };
}
