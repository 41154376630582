import { Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { typography } from './typography';
import { palette } from './palette';

import { overrides } from './overrides';

const generateTheme = (): Theme => {
  const theme = createTheme({
    palette,
    typography,
    shape: {
      borderRadius: 2,
    },
  });
  theme.components = overrides(theme);

  return theme;
};

export default generateTheme;
