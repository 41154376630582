import { alpha } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    tetriary: Palette['primary'];
  }
  interface PaletteOptions {
    tetriary: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tetriary: true;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    darken: string;
    lighten: string;
  }
}

export const PRIMARY = {
  light: '#E7E7E7',
  main: '#E2E2E2',
  dark: '#9E9E9E',
};

export const SECONDARY = {
  light: '#636363',
  main: '#3D3D3D',
  dark: '#2A2A2A',
};

// Medium
export const TETRIARY = {
  lighten: '#FFF6E4',
  light: '#FFFCB9',
  main: '#FFC700',
  dark: '#FAAD14',
};

// Low
export const INFO = {
  lighten: '#E4EFFF',
  light: '#ECFCFF',
  main: '#62CFFF',
  dark: '#2F7AEB',
};

// Critical
export const ERROR = {
  light: '#FFEEED',
  main: '#F20B0B',
  dark: '#8A374F',
};

export const SUCCESS = {
  light: alpha('#57963A', 0.2),
  main: '#57963A',
  dark: '#57963A',
};

// High
export const WARNING = {
  light: '#FFEFCF',
  main: '#F89439',
  dark: '#FF6B18',
};

export const GREY = {
  50: '#FCFCFC',
  100: '#F7F7F7',
  200: '#EAEAEA',
  300: '#E0E0E0',
  400: '#D2D2D2',
  500: '#9E9E9E',
  600: '#6C6C6C',
  700: '#55524F',
  800: '#3E3E3E',
  900: '#2E2E2E',
  A100: '#F5F5F5',
  A200: '#EEEEEE',
  A300: '#E0E0E0',
  A700: '#616161',
};
