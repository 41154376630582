import { Components, Theme } from '@mui/material';

export default function Dialog(theme: Theme): Components {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: 0,
          minWidth: 300,
          border: `1px solid ${theme.palette.common.white}`,
          borderRadius: Number(theme.shape.borderRadius) * 2,
          overflow: 'hidden',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: theme.spacing(2, 2.5, 3),
          background: 'linear-gradient(180deg, #1A2F36 0%, #283A3A 121.08%)',
          color: '#F4F5FA',
          fontSize: theme.typography.h2.fontSize,
          lineHeight: theme.typography.h2.lineHeight,
          fontWeight: theme.typography.h2.fontWeight,
          fontFamily: theme.typography.h2.fontFamily,
          '& + .MuiDialogContent-root': {
            paddingTop: `${theme.spacing(2)} !important`,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          position: 'relative',
          background: theme.palette.common.white,
          margin: theme.spacing(-1, '-1px', 0),
          minHeight: 100,
          zIndex: 1,
          padding: theme.spacing(2, 2.5),
          color: theme.palette.text.primary,
          fontSize: theme.typography.body2.fontSize,
          lineHeight: theme.typography.body2.lineHeight,
          fontFamily: theme.typography.body2.fontFamily,
          fontWeight: theme.typography.body2.fontWeight,
          borderRadius: '8px 8px 0 0',

          '&:before': {
            content: "''",
            position: 'absolute',
            background: theme.palette.grey.A100,
            top: 1,
            left: 1,
            bottom: 0,
            right: 1,
            borderRadius: '8px 8px 0 0',
            zIndex: -1,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0, 2.5, 2),
          gap: theme.spacing(1),
        },
      },
    },
  };
}
