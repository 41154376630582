import React, { useContext, createContext, useState } from 'react';

interface FilterContextData {
  filterOptions: any;
  updateFilterOptions: (newValue: any) => void;
  appliedFilter: any;
  updateAppliedFilter: (newValue: any) => void;
}

//create the context
const FilterContext = createContext<FilterContextData>({
  filterOptions: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateFilterOptions: () => {},
  appliedFilter: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateAppliedFilter: () => {},
});

export function FilterContextProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const storedFilteroptions = localStorage.getItem('filterOptions');
  const storedAppliedFilters = localStorage.getItem('appliedFilters');

  const initFilteroptions = storedFilteroptions ? JSON.parse(storedFilteroptions) : {};
  const initAppliedFilters = storedAppliedFilters ? JSON.parse(storedAppliedFilters) : {};

  const [filterOptions, setFilterOptions] = useState<any>({});
  const [appliedFilter, setAppliedFilter] = React.useState(initAppliedFilters);

  const updateFilterOptions = (newValue: any): void => {
    setFilterOptions(newValue);
    localStorage.setItem('filterOptions', JSON.stringify(newValue));
  };
  const updateAppliedFilter = (newValue: any): void => {
    setAppliedFilter(newValue);
    localStorage.setItem('appliedFilters', JSON.stringify(newValue));
  };

  const contextValue: FilterContextData = {
    filterOptions,
    updateFilterOptions,
    appliedFilter,
    updateAppliedFilter,
  };

  return <FilterContext.Provider value={contextValue}>{children}</FilterContext.Provider>;
}

// Custom hook to access the context
export const useFilterContext = (): FilterContextData => {
  return useContext(FilterContext);
};
