import { lazy } from 'react';

export const HomePageLazy = lazy(() =>
  import('src/routes/pages/home-page/home-page').then((module) => ({
    default: module.HomePage,
  })),
);

export const ProfilePageLazy = lazy(() =>
  import('src/routes/pages/profile-page/profile-page').then((module) => ({
    default: module.ProfilePage,
  })),
);

export const IncidentsPageLazy = lazy(() =>
  import('src/routes/pages/incidents-page/IncidentsPage').then((module) => ({
    default: module.IncidentsPage,
  })),
);
export const StoryboardPageLazy = lazy(() =>
  import('src/routes/pages/story-board-page/StoryboardPage').then((module) => ({
    default: module.StoryboardPage,
  })),
);

export const SingleIncidentPageLazy = lazy(() =>
  import('src/routes/pages/single-incident-page/SingleIncidentPage').then((module) => ({
    default: module.SingleIncidentPage,
  })),
);

export const EventsPageLazy = lazy(() =>
  import('src/routes/pages/events-page/EventsPage').then((module) => ({
    default: module.EventsPage,
  })),
);

export const SingleEventPageLazy = lazy(() =>
  import('src/routes/pages/single-event-page/SingleEventPage').then((module) => ({
    default: module.SingleEventPage,
  })),
);

export const SingleStoryboardPageLazy = lazy(() =>
  import('src/routes/pages/single-storyboard-page/SingleStoryboardPage').then((module) => ({
    default: module.SingleStoryboardPage,
  })),
);
