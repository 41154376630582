import { useLocation } from 'react-router-dom';
import { ERoutes } from 'src/types/routes.enum';

export const useGetPageIsActive = (): ((path: string) => boolean) => {
  const location = useLocation();

  return function getPageIsActive(path: string) {
    return (
      location.pathname === path || (path !== ERoutes.Home && location.pathname.includes(path))
    );
  };
};
