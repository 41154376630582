export enum ERoutes {
  Home = '/',
  Profile = '/profile',
  Events = '/events',
  Event = '/event',
  Incidents = '/incidents',
  Incident = '/incident',
  Storyboard = '/storyboard',
  SingleStoryboard = '/singleStoryboard',
  Algorythm = '/algorythm',
  Register = '/register',
}
