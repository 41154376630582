import { Components, Theme } from '@mui/material';

export default function Card(theme: Theme): Components {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
          color: theme.palette.common.black,
          backgroundColor: theme.palette.common.white,
          padding: theme.spacing(2, 2.5),
          boxShadow: '0px 7px 17px rgba(0, 0, 0, 0.04)',
          clipPath: 'none', //'polygon(0 0, calc(100% - 20px) 0, 100% 16px, 100% 100%, 0 100%)',
          '&::before': {
            content: "''",
            position: 'absolute',
            top: 1,
            left: 1,
            right: 1,
            bottom: 1,
            backgroundColor: theme.palette.grey.A100,
            clipPath: 'none', //'polygon(0 0, calc(100% - 20px) 0, 100% 16px, 100% 100%, 0 100%)',
            zIndex: -1,
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          '&:last-child': {
            padding: 0,
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          position: 'relative',
          color: theme.palette.text.primary,
          margin: theme.spacing(-2, -2.5, 0),
          padding: theme.spacing(2, 2.5, 2),
          '&::after': {
            position: 'absolute',
            content: "''",
            left: 20,
            right: 20,
            bottom: 0,
            height: '1px',
            backgroundColor: theme.palette.grey[200],
          },
        },
      },
    },
  };
}
