import { pxToRem } from './utils/font.utils';

const SORA_FONT = "'Sora', sans-serif";
const MULISH_FONT = "'Mulish', sans-serif";
const INTER_FONT = "'Inter', sans-serif";
const ROBOTO_FONT = "'Roboto', sans-serif";

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1_Roboto: React.CSSProperties;
    h2_Mulish: React.CSSProperties;
    h2_Roboto: React.CSSProperties;
    h3_Roboto: React.CSSProperties;
    h3_Mulish: React.CSSProperties;
    subtitle_Roboto: React.CSSProperties;
    subtitle_Mulish: React.CSSProperties;
    subtitle_Sora: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1_Roboto: React.CSSProperties;
    h2_Mulish: React.CSSProperties;
    h2_Roboto: React.CSSProperties;
    h3_Roboto: React.CSSProperties;
    h3_Mulish: React.CSSProperties;
    subtitle_Roboto: React.CSSProperties;
    subtitle_Mulish: React.CSSProperties;
    subtitle_Sora: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1_Roboto: true;
    h2_Mulish: true;
    h2_Roboto: true;
    h3_Roboto: true;
    h3_Mulish: true;
    subtitle_Roboto: true;
    subtitle_Mulish: true;
    subtitle_Sora: true;
  }
}

export const typography = {
  fontFamily: INTER_FONT,
  h1: {
    fontSize: pxToRem(20),
    lineHeight: 24 / 20,
    fontWeight: 400,
    fontFamily: INTER_FONT,
  },
  h1_Roboto: {
    fontSize: pxToRem(20),
    lineHeight: 23 / 20,
    fontWeight: 400,
    fontFamily: ROBOTO_FONT,
  },

  h2: {
    fontSize: pxToRem(16),
    lineHeight: 19 / 16,
    fontWeight: 400,
    fontFamily: INTER_FONT,
  },
  h2_Mulish: {
    fontSize: pxToRem(16),
    lineHeight: 20 / 16,
    fontWeight: 400,
    fontFamily: MULISH_FONT,
  },
  h2_Roboto: {
    fontSize: pxToRem(16),
    lineHeight: 19 / 14,
    fontWeight: 400,
    fontFamily: ROBOTO_FONT,
  },

  h3: {
    fontSize: pxToRem(14),
    lineHeight: 20 / 14,
    fontWeight: 400,
    fontFamily: INTER_FONT,
  },
  h3_Mulish: {
    fontSize: pxToRem(14),
    lineHeight: 18 / 14,
    fontWeight: 400,
    fontFamily: MULISH_FONT,
  },
  h3_Roboto: {
    fontSize: pxToRem(14),
    lineHeight: 20 / 14,
    fontWeight: 400,
    fontFamily: ROBOTO_FONT,
  },

  subtitle1: {
    fontSize: pxToRem(12),
    lineHeight: 15 / 12,
    fontWeight: 400,
    fontFamily: INTER_FONT,
  },
  subtitle_Roboto: {
    fontSize: pxToRem(12),
    lineHeight: 15 / 12,
    fontWeight: 400,
    fontFamily: ROBOTO_FONT,
  },
  subtitle_Mulish: {
    fontSize: pxToRem(12),
    lineHeight: 15 / 12,
    fontWeight: 400,
    fontFamily: MULISH_FONT,
  },
  subtitle_Sora: {
    fontSize: pxToRem(12),
    lineHeight: 15 / 12,
    fontWeight: 400,
    fontFamily: SORA_FONT,
  },

  body1: {
    fontSize: pxToRem(12),
    lineHeight: 14.5 / 12,
    fontWeight: 400,
    fontFamily: INTER_FONT,
  },
  body2: {
    fontSize: pxToRem(12),
    lineHeight: 15 / 12,
    fontWeight: 400,
    fontFamily: MULISH_FONT,
  },
  caption: {
    fontSize: pxToRem(10),
    lineHeight: 13 / 10,
    fontWeight: 400,
    fontFamily: SORA_FONT,
  },
  button: {
    fontSize: pxToRem(12),
    lineHeight: 15 / 12,
    fontWeight: 600,
    fontFamily: INTER_FONT,
  },
};
