import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from 'react-modal-hook';
import { TransitionGroup } from 'react-transition-group';
import { ReactQueryProvider } from 'src/core/providers/react-query.provider';
import { Auth0Provider } from 'src/core/providers/auth0.provider';

import { App } from './App';
import { CssBaseline, ThemeProvider } from '@mui/material';
import generateTheme from 'src/theme/theme';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { CustomApolloProvider } from './core/providers/apollo-provider';
import { EventContextProvider } from './context/EventContext';
import { FilterContextProvider } from './context/FilterContext';

dayjs.extend(relativeTime);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider>
        <CustomApolloProvider>
          <ReactQueryProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ThemeProvider theme={generateTheme()}>
                <ModalProvider rootComponent={TransitionGroup}>
                  <EventContextProvider>
                    <FilterContextProvider>
                      <CssBaseline />
                      <App />
                    </FilterContextProvider>
                  </EventContextProvider>
                </ModalProvider>
              </ThemeProvider>
            </LocalizationProvider>
          </ReactQueryProvider>
        </CustomApolloProvider>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>,
);
