import React, { PropsWithChildren } from 'react';
import { Auth0Provider as Auth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export const Auth0Provider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: { returnTo?: string }): void => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_TO || window.location.origin}
      cacheLocation='localstorage'
      useCookiesForTransactions={true}
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
      scope={process.env.REACT_APP_AUTH0_SCOPE || ''}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE || ''}
    >
      {children}
    </Auth0>
  );
};
