import { EdgeSidebar, Root, SidebarContent } from '@mui-treasury/layout';
import { Outlet } from 'react-router-dom';
import { NavigationMenu } from 'src/routes/layouts/default-layout/components/NavigationMenu';

export const DefaultLayout = (): JSX.Element => {
  return (
    <Root
      scheme={{
        header: {
          config: {
            xs: {
              position: 'sticky',
              height: 56,
            },
            md: {
              position: 'relative',
              height: 64,
            },
          },
        },
        leftEdgeSidebar: {
          config: {
            xs: {
              variant: 'temporary',
              width: 'auto',
            },
            sm: {
              variant: 'permanent',
              width: 52,
            },
          },
        },
      }}
    >
      <EdgeSidebar anchor='left'>
        <SidebarContent>
          <NavigationMenu />
        </SidebarContent>
      </EdgeSidebar>
      <Outlet />
    </Root>
  );
};
