import { Components, Theme } from '@mui/material';

export default function Tabs(theme: Theme): Components {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 'auto',
        },
        indicator: {
          height: 2,
          backgroundColor: theme.palette.common.black,
          pointerEvents: 'none',
          clipPath: 'none',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(0, 1, 1),
          fontSize: theme.typography.h3_Roboto.fontSize,
          fontWeight: 500, //theme.typography.h4.fontWeight,
          color: theme.palette.text.disabled,
          minHeight: 'auto',
          textTransform: 'none',

          '&:hover': {
            color: theme.palette.common.black,
          },

          '&.Mui-selected': {
            color: theme.palette.common.black,
          },
        },
      },
    },
  };
}
