import { Components, Theme } from '@mui/material';

export default function Breadcrumbs(theme: Theme): Components {
  return {
    MuiBreadcrumbs: {
      defaultProps: {
        separator: '/',
      },
      styleOverrides: {
        root: {
          '& .MuiLink-root': {
            display: 'flex',
          },
        },
        separator: {
          fontSize: theme.typography.h2.fontSize,
          fontWeight: theme.typography.h3.fontWeight,
          color: theme.palette.text.primary,
          marginLeft: theme.spacing(0.25),
          marginRight: theme.spacing(0.25),
        },
      },
    },
  };
}
