import { Components } from '@mui/material';
import { pxToRem } from '../utils/font.utils';

export default function Icons(): Components {
  return {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(16),
        },
        fontSizeSmall: {
          fontSize: pxToRem(12),
        },
      },
    },
  };
}
