import { ChevronDownIcon } from './../customIcons';
import { Components, Theme } from '@mui/material';

export default function Select(theme: Theme): Components {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: ChevronDownIcon,
      },
      styleOverrides: {
        select: {
          boxSizing: 'border-box',
          height: 32,
          fontWeight: 500,
          lineHeight: '16px',
          paddingLeft: theme.spacing(1.5),
          color: theme.palette.text.primary,
          fontSize: 12,
        },
        icon: {
          fontSize: 12,
        },
      },
    },
  };
}
