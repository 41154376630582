import { Box, Stack } from '@mui/material';
import { ERoutes } from 'src/types/routes.enum';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '@mui/material/styles';
import { NavigationLink } from './NavigationLink';
import { LogoutIcon, SettingsIcon } from 'src/theme/customIcons';

import Logo from 'src/assets/Logo.svg';
import { useGetMainNavConfig } from 'src/hooks/main-navigation.hook';
import { NavigationItem } from './NavigationItem';
import { useGetPageIsActive } from 'src/hooks/active-page-hook';

export const NavigationMenu = (): JSX.Element => {
  const { logout, isAuthenticated } = useAuth0();
  const theme = useTheme();
  const navigationItems = useGetMainNavConfig();
  const getPageIsActive = useGetPageIsActive();

  return (
    <Stack
      sx={{
        height: 1,
        display: 'grid',
        gridTemplateRows: '52px 1fr 52px',
      }}
    >
      <NavigationLink
        to={ERoutes.Home}
        icon={<Box component='img' src={Logo} />}
        sx={{ borderBottom: `1px solid ${theme.palette.grey[800]}`, borderTopRightRadius: 4 }}
      />
      <Stack
        sx={{
          backgroundColor: '#1A2326',
          gap: 3,
          height: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isAuthenticated &&
          navigationItems.map((item) => (
            <NavigationItem
              menuItem={item}
              key={item.name}
              isActive={getPageIsActive(item.route)}
            />
          ))}
      </Stack>
      <NavigationLink
        onClick={() =>
          isAuthenticated &&
          logout({
            returnTo: window.location.origin,
          })
        }
        icon={isAuthenticated ? <LogoutIcon /> : <></>}
        // sx={{ borderBottomRightRadius: 4, }}
      />
    </Stack>
  );
};
