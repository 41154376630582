import { Components, Theme } from '@mui/material';

export default function Button(theme: Theme): Components {
  return {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'medium',
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          height: 32,
          padding: theme.spacing(0, 1.25),
          borderRadius: theme.shape.borderRadius,
          boxShadow: `0px 2px 8px rgba(0,0,0,0.04)`,
          boxSizing: 'border-box',
          '&:hover, &:active, &:focus': {
            boxShadow: `0px 2px 8px rgba(0,0,0, 0.04)`,
          },
          textTransform: 'none',
        },
        startIcon: {
          fontSize: 12,
          marginLeft: 0,
          '&>*:nth-of-type(1)': {
            fontSize: 12,
          },
        },
        endIcon: {
          fontSize: 12,
          marginRight: 0,
          '&>*:nth-of-type(1)': {
            fontSize: 12,
          },
        },
        iconSizeMedium: {
          fontSize: 12,
        },
        containedPrimary: {
          background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main})`,
          color: theme.palette.grey['A700'],
          border: '1px solid #888480',
          '&:hover, &:active': {
            background: `grey.100`,
          },
          '&:focus-visible': {
            outline: `1px solid ${theme.palette.common.white}`,
            outlineOffset: -1,
            background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main})`,
          },
          '&:disabled': {
            background: `grey.200`,
            color: theme.palette.grey[50],
            border: `1px solid ${theme.palette.grey[50]}`,
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.04)',
            cursor: 'not-allowed',
          },
        },
        containedSecondary: {
          color: 'common.black', //theme.palette.primary.main,
          border: '1px solid #888480', //`1px solid ${theme.palette.common.white}`,
          background: `linear-gradient(${theme.palette.grey.A100}, ${theme.palette.grey.A100})`,
          '&:hover, &:active': {
            background: `linear-gradient(180deg, #EEF0F4 0%, #FEFEFF 100%);`,
          },
          '&:focus': {
            background: `linear-gradient(${theme.palette.common.white}, ${theme.palette.common.white});`,
            borderColor: theme.palette.primary.main,
          },
          borderRadius: '4px',
        },
        text: {
          padding: 0,
          background: 'none',
          boxShadow: 'none',
          minHeight: 0,
          height: 'auto',
          '&:hover': {
            textDecoration: 'underline',
            background: 'none',
          },
          '& .MuiButton-startIcon ': {
            marginRight: theme.spacing(0.5),
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
        disableFocusRipple: true,
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: {
          color: theme.palette.grey[900],
        },
        colorInherit: {
          color: 'inherit',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          border: `1px solid ${theme.palette.common.white}`,
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          border: 'none',
          marginLeft: '0 !important',
          padding: theme.spacing(0, 1),
          height: 30,
          background: theme.palette.grey.A100,
          color: theme.palette.primary.main,

          '&:hover': {
            background: `linear-gradient(180deg, #EEF0F4 0%, #FEFEFF 100%);`,
          },

          '&.Mui-selected': {
            background: theme.palette.primary.main,
            color: theme.palette.grey[50],
            '&:hover': {
              background: theme.palette.primary.main,
            },
          },
        },
      },
    },
  };
}
