import type {} from '@mui/x-date-pickers/themeAugmentation';
import { Components, Theme } from '@mui/material';
import { CalendarIcon } from 'src/theme/customIcons';

export default function DatePicker(theme: Theme): Components {
  return {
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          width: 260,
        },
        paper: {
          boxShadow: '0px 5.59064px 13.5773px rgba(0, 0, 0, 0.04)',
          border: `1px solid ${theme.palette.grey[200]}`,
          background: theme.palette.common.white,
          borderRadius: Number(theme.shape.borderRadius) * 2,
          marginTop: theme.spacing(0.5),
          transform: 'translateX(36px) !important',
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          position: 'relative',
          marginTop: theme.spacing(1.5),
          marginBottom: theme.spacing(1.5),
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1.5),
        },
        labelContainer: {
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          '& .MuiButtonBase-root': {
            display: 'none',
          },
        },
        label: {
          margin: 0,
          color: theme.palette.common.black,
          fontSize: theme.typography.subtitle_Mulish.fontSize,
          fontFamily: theme.typography.subtitle_Mulish.fontFamily,
          fontWeight: 700,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
          backgroundColor: 'transparent',
          color: theme.palette.text.primary,
          borderRadius: theme.shape.borderRadius,
          fontSize: theme.typography.subtitle_Mulish.fontSize,
          fontFamily: theme.typography.subtitle_Mulish.fontFamily,
          fontWeight: 500,
          margin: '0 1px',
          '&:hover': {
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.04)',
            background: 'linear-gradient(180deg, #F4F4F4 0%, #FFFFFF 100%)',
          },
          '&.Mui-selected': {
            color: theme.palette.secondary.main,
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.04)',
            border: `1px solid ${theme.palette.common.white}`,
            background: theme.palette.grey.A200,

            '&:hover, &:focus, &:focus-visible, &:active': {
              background: theme.palette.grey.A200,
            },
          },
          '&.Mui-disabled': {
            background: 'transparent',
            opacity: 0.4,
            cursor: 'not-alowed',
          },
          '&.MuiPickersDay-today': {
            borderColor: theme.palette.grey[200],
          },
        },
      },
    },
    MuiDayPicker: {
      styleOverrides: {
        weekDayLabel: {
          height: 32,
          width: 32,
          fontSize: theme.typography.subtitle_Mulish.fontSize,
          fontFamily: theme.typography.subtitle_Mulish.fontFamily,
          fontWeight: 500,
          color: theme.palette.text.primary,
          margin: '0 1px',
        },
      },
    },
    PrivatePickersYear: {
      styleOverrides: {
        root: {
          flexBasis: `${(1 / 3) * 100}%`,

          '& .PrivatePickersYear-yearButton': {
            margin: 0,
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,
            borderRadius: theme.shape.borderRadius,
            fontSize: theme.typography.subtitle_Mulish.fontSize,
            fontFamily: theme.typography.subtitle_Mulish.fontFamily,
            fontWeight: 500,
            '&:hover': {
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.04)',
              background: 'linear-gradient(180deg, #F4F4F4 0%, #FFFFFF 100%)',
            },
            '&.Mui-selected': {
              color: theme.palette.secondary.main,
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.04)',
              border: `1px solid ${theme.palette.common.white}`,
              background: theme.palette.grey.A200,

              '&:hover, &:focus, &:focus-visible, &:active': {
                background: theme.palette.grey.A200,
              },
            },

            '&.Mui-disabled': {
              background: 'transparent',
              opacity: 0.4,
              cursor: 'not-alowed',
            },
          },
        },
      },
    },
    PrivatePickersSlideTransition: {
      styleOverrides: {
        root: {
          minHeight: '220px !important',
        },
      },
    },
    MuiCalendarOrClockPicker: {
      styleOverrides: {
        root: {
          '& > div': {
            width: 260,
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          width: 260,
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        root: {
          width: '100%',
          justifyContent: 'space-between',
        },
      },
    },
    MuiDesktopDatePicker: {
      defaultProps: {
        disableFuture: true,
        components: {
          OpenPickerIcon: CalendarIcon,
        },
      },
    },
  };
}
