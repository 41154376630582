import { alpha, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IMainNavigationItem } from 'src/hooks/main-navigation.hook';

interface IProps {
  menuItem: IMainNavigationItem;
  isActive?: boolean;
}

export const NavigationItem = ({ menuItem: { route, icon }, isActive }: IProps): JSX.Element => {
  const navigate = useNavigate();
  const theme = useTheme();

  const activeStyles = useMemo(
    () => ({
      borderColor: alpha(theme.palette.primary.light, 0.3),
      color: theme.palette.primary.light,
      background: 'linear-gradient(221.85deg, #111419 8.3%, rgba(17, 20, 25, 0) 92.11%)',
    }),
    [theme],
  );

  return (
    <IconButton
      onClick={() => navigate(route)}
      sx={{
        boxSizing: 'border-box',
        height: '36px',
        width: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid transparent',
        borderRadius: 1,
        color: 'grey.100',
        background: 'linear-gradient(rgba(0,0,0,0))',
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.standard,
        }),

        '&:hover': {
          background: 'linear-gradient(221.85deg, #111419 8.3%, rgba(17, 20, 25, 0) 92.11%);',
        },
        '&:active, &:focus-visible': {
          ...activeStyles,
        },
        ...(isActive && activeStyles),
      }}
    >
      {icon}
    </IconButton>
  );
};
