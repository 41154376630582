import { Components } from '@mui/material';

export default function Checkbox(): Components {
  return {
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  };
}
