import IconButton from '@mui/material/IconButton';
import { SxProps } from '@mui/system';
import { useNavigate } from 'react-router-dom';

interface IProps {
  to?: string;
  icon: JSX.Element;
  sx?: SxProps;
  onClick?(): void;
}

export const NavigationLink = ({ to, icon, sx, onClick }: IProps): JSX.Element => {
  const navigate = useNavigate();

  const onButtonClick = (): void => {
    if (to) {
      navigate(to);
    }
    onClick && onClick();
  };

  return (
    <IconButton
      onClick={onButtonClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'grey.100',
        background: 'linear-gradient(180deg, #192124 0%, #283A3A 100%)',
        cursor: 'pointer',
        borderRadius: 0,
        boxShadow: '0px 0px 17px rgba(0, 0, 0, 0.3), inset 0px -14px 18px rgba(0, 0, 0, 0.4)',
        ...sx,
      }}
    >
      {icon}
    </IconButton>
  );
};
