import { PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 500,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

export const ReactQueryProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* TODO: Uncomment for react-queries cache ui */}
      {/*<ReactQueryDevtools initialIsOpen={false} />*/}
    </QueryClientProvider>
  );
};
